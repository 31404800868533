import * as React from "react"
import { Helmet } from 'react-helmet'
import { useSiteMetadata } from '../hooks/use-site-metadata';
import './404.scss';
import Header from '../components/header';
import { Link } from 'gatsby';

// markup
const NotFoundPage = () => {
    const { title } = useSiteMetadata();

    return (
        <main className="not-found-page">
            <Helmet title={title}/>
            <Header hideNav/>
            <div className="page-content">
                <div className="wrapper">
                    <h1>404: Page not Found</h1>
                    <div className="subtitle">Sorry we can’t seem to find that page.
                        Here are some helpful links instead:
                    </div>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/explore">Explore the data</Link></li>
                        <li><Link to="/about">About</Link></li>
                    </ul>
                </div>
            </div>
        </main>
    )
}

export default NotFoundPage
